.app-container {
  background-color: black;
  min-height: 100vh;
  color: orange;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.header {
  text-align: center;
  color: orange;
}

.category {
  margin-bottom: 20px;
}

.question {
  margin: 10px 0;
}

.options button {
  background-color: orange;
  color: black;
  margin: 5px;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.options button:hover {
  background-color: darkorange;
}

.result ul {
  list-style-type: none;
  padding: 0;
}

.result li {
  margin: 5px 0;
}
