/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Background and Font */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f7f7f7;
  color: #333;
}

/* Container for the Questionnaire */
.questionnaire {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

/* Styling for Each Category */
.category {
  margin-bottom: 30px;
}

.category h2 {
  font-size: 1.5em;
  color: #ff7f50; /* Orange color for category titles */
  margin-bottom: 15px;
  border-bottom: 2px solid #333;
  padding-bottom: 5px;
}

/* Styling for Questions */
.question {
  margin-bottom: 20px;
}

.question p {
  font-size: 1.1em;
  margin-bottom: 10px;
}

/* Options Buttons */
.options {
  display: flex;
  gap: 10px;
}

.options button {
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.options button:hover {
  background-color: #e0e0e0;
}

.options button.selected {
  background-color: #ff7f50; /* Orange for selected options */
  color: #fff;
  border-color: #ff7f50;
}

/* Submit Button */
button.submit-btn {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

button.submit-btn:hover {
  background-color: #555;
}






.options {
  margin-top: 10px;
}

button {
  margin: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f5f5f5;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button.selected {
  background-color: #ff6f00; /* Orange color for selected */
  color: white;
  border-color: #ff6f00;
}

button:hover {
  background-color: #e0e0e0;
}
